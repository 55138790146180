import modal from './walletconnect.js';
import { BrowserProvider, Contract, formatUnits, parseEther } from 'ethers';

import { abi, contractAddress } from './contractAbis/ContractFile';

let signer;
let contract;
let balanceFormatted;
let ethersProvider;

/**
 * @param {string} 
 * @param {string} 
 * @returns {Array}
 */

function editaddress(address, char) {
    const spans = [];
    for (let i = 0; i < address.length; i++) {
        if (address[i] === char) {
            spans.push(`<span class="upsidedown">${address[i]}</span>`)
        } else {
            spans.push(`<span>${address[i]}</span>`)
        }
    }

    return spans.join('');
}

const returnuseraddress = (address) => {
    return address.length ?
        `<p class="useraddr" id="moveIn">${editaddress(address, 'a')}</p>` : ``
}

async function handleChange({ provider, providerType, address, error, chainId, isConnected }) {
    try {
        if (isConnected && address) {
            document.getElementById('mineform').classList.add('moveIn');
            const useraddress = document.getElementById("useraddress");

            useraddress.insertAdjacentHTML('beforeend', returnuseraddress(address));

            ethersProvider = new BrowserProvider(provider);
            signer = await ethersProvider.getSigner();

            contract = new Contract(
                contractAddress,
                abi,
                signer
            );

            const minebutton = document.getElementById('mine-button');
            const balance = await ethersProvider.getBalance(address);
            balanceFormatted = formatUnits(balance, 18);

            //console.log('balanceFormatted:', balanceFormatted);

            //console.log(document.getElementById('user-balance'))

            document.getElementById('user-balance').innerHTML = `${balanceFormatted} ETH`;

            minebutton.addEventListener('click', enter);
        } else {
            const useraddress = document.getElementById("useraddress");
            document.getElementById('mineform').classList.remove('moveIn');
            useraddress.innerHTML = '';
        }
    } catch (error) {
        console.log(error)
    }
}

async function enter() {
    try {
        if (balanceFormatted > 0) {
            const mineinput = document.getElementById('mine-input');
            const value = mineinput.value;

            const amountToSend = parseEther(value.toString());

            const tx = await contract.register({
                value: amountToSend
            });

            await tx.wait();

            const balance = await ethersProvider.getBalance(address);
            balanceFormatted = formatUnits(balance, 18);

            document.getElementById('user-balance').innerHTML = `${balanceFormatted} ETH`;
        }
    } catch (error) {
        console.log(error);
    }
}

modal.subscribeProvider(handleChange);

const join = document.getElementById('join');

join.addEventListener('click', () => modal.open());